<template>
  <div class="disclaimer articleBox">
    <div class="container articleContent">
      <div
        class="disclaimer-title listItemTitle"
        v-html="$t('about.disclaimer.title')"
      ></div>
      <div
        class="disclaimer-content"
        v-html="$t('about.disclaimer.content')"
      ></div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.disclaimer {
  &-title {
    margin-top: 0;
    padding: 0px 0 17px;
    border-bottom: 1px solid var(--borderColor);
  }
  &-content {
    // padding-top: 24px;
  }
  min-height: calc(100vh - 327px);
  ol,
  ul,
  li {
    list-style: auto;
  }
  *[type="i"] li {
    list-style-type: lower-roman;
  }
  *[type="a"] li {
    list-style-type: lower-alpha;
  }
  *[type="1"] li {
    list-style-type: decimal;
  }
}
</style>
